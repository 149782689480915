import popularCollectionImage1 from '../images/popularCollectionsImages/popularCollectionImage1.jpg'
import popularCollectionImage2 from '../images/popularCollectionsImages/popularCollectionImage2.jpg'
import popularCollectionImage3 from '../images/popularCollectionsImages/popularCollectionImage3.jpg'
import popularCollectionImage4 from '../images/popularCollectionsImages/popularCollectionImage4.jpg'
import popularCollectionImage5 from '../images/popularCollectionsImages/popularCollectionImage5.jpg'
import popularCollectionImage6 from '../images/popularCollectionsImages/popularCollectionImage6.jpg'


import yann from '../images/avatar/yann.jpg'


const popularCollectionData = [
    {
        title: "Carré Collection",
        imgAuthor: yann,
        name: "Yann Faisant",
        imgleft: popularCollectionImage1,
        imgright1: popularCollectionImage2,
        imgright2: popularCollectionImage3,
        imgright3: popularCollectionImage4,
        imgright4: popularCollectionImage5,
        imgtop: popularCollectionImage6,
        wishlist: "100",
        count: '12 item products'
    }

]

export default popularCollectionData;