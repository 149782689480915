import Home01 from "./Home01";
import Home02 from "./Home02";
import Home03 from "./Home03";
import Home04 from "./Home04";
import Home05 from "./Home05";
import Home06 from "./Home06";
import Home07 from "./Home07";
import Home08 from "./Home08";
import Explore01 from "./Explore01";
import Explore02 from "./Explore02";
import Explore03 from "./Explore03";
import Explore04 from "./Explore04";
import LiveAuctions from "./LiveAuctions";
import ItemDetails01 from "./ItemDetails01";
import ItemDetails02 from "./ItemDetails02";
import Activity01 from "./Activity01";
import Activity02 from "./Activity02";
import Blog from "./Blog";
import BlogDetails from "./BlogDetails";
import HelpCenter from "./HelpCenter";
import Authors01 from "./Authors01";
import Authors02 from "./Authors02";
import WalletConnect from "./WalletConnect";
import CreateItem from "./CreateItem";
import EditProfile from "./EditProfile";
import Ranking from "./Ranking";
import Login from "./Login";
import SignUp from "./SignUp";
import NoResult from "./NoResult";
import FAQ from "./FAQ";
import Contact01 from "./Contact01";
import Contact02 from "./Contact02";
import Collections from "./Collections";
import PixelizedMosaic from "./PMcollection";
import Terms from "./terms";
import Privacy from "./privacy";
import MyArtworks from "./myArtworks";
import ReferralHome from "./referralHome";
import Drops from "./Drops";
import Profile from "./Profile";
import Affiliate from "./Affiliate";
import Referral from "./Referral";
import HybridNFT from "./hybrid_nft";
import YannFaisantProfile from "./YannFaisant";
import LikedItems from "./LikedItems";
import ReferralPage from "./ReferralkPage";
import HybridArticle from "./HybridBlogArticle";
import ArtistInfos from "./artistInfos";
import CreateCollection from "./createCollection";
import CreateChoices from "./createChoices"
import SettingsPage from "./settingsPage"
import CreatePublicCollection from "./createPublicCollection";
import Tokenize from "./tokenize";
import Premiers from "./bidPage1";
import MyCollections from "./myCollections";
import Tokenized from "./tokenized";
import MyPendingArtworks from "./myPendingArtworks";
import Ressources from './ressources'
import LazyDisplay from "./lazyDisplay";
import ListItem from "./listNFT";
import Artwork from "./artwork";
import CollectionItems from "./collectionItems";
import DisplayProfile from "./DisplayProfile";
import Collection from "../components/layouts/Collections/Collection";
import Learn from "./Learn";
import LearnResourceDetail from "./LearnResourceDetail";
import OrderTracking from "./OrderTracking/OrderTracking";
import OfferList from "./OfferSystem/OfferList";
import OfferSent from "./OfferSystem/OfferSent";
import ConfirmationPage from "./confirmationpage";

const routes = [
  { path: '/', component: <Home01 />},
  { path: '/artist-info', component: <ArtistInfos />},
  { path: '/private-display', component: <LazyDisplay />},
  { path: '/collection-items', component: <CollectionItems />},
  { path: '/artwork-details', component: <Artwork />},
  { path: '/list-item', component: <ListItem />},
  { path: '/ressources', component: <Ressources />},
  { path: '/home-02', component: <Home02 />},
  { path: '/home-03', component: <Home03 />},
  { path: '/home-04', component: <Home04 />},
  { path: '/home-05', component: <Home05 />},
  { path: '/home-06', component: <Home06 />},
  { path: '/home-07', component: <Home07 />},
  { path: '/home-08', component: <Home08 />},
  { path: '/explore-01', component: <Explore01 />},
  { path: '/terms-of-service', component: <Terms />},
  { path: '/privacy-policy', component: <Privacy />},
  { path: '/my-artworks', component: <MyArtworks />},
  { path: '/profile', component: <Profile />},
  { path: '/likedItems', component: <LikedItems />},
  { path: '/referral', component: <Referral />},
  { path: '/hybrid_nft', component: <HybridNFT />},
  { path: '/affiliate_program', component: <Affiliate />},
  { path: '/referral-program', component: <ReferralHome />},
  { path: '/Referral', component: <ReferralPage />},
  { path: '/pixelizd-mosaic-collection', component: <PixelizedMosaic />},
  { path: '/explore-02', component: <Explore02 />},
  { path: '/explore-03', component: <Explore03 />},
  { path: '/explore-04', component: <Explore04 />},
  { path: '/live-auctions', component: <LiveAuctions />},
  { path: '/item-details-01', component: <ItemDetails01 />},
  { path: '/item-details-02', component: <ItemDetails02 />},
  { path: '/activity-01', component: <Activity01 />},
  { path: '/activity-02', component: <Activity02 />},
  { path: '/drops', component: <Drops />},
  { path: '/blog', component: <Blog />},
  { path: '/blog-details/Hybrid-NFTs', component: <HybridArticle />},
  { path: '/blog-details', component: <BlogDetails />},
  { path: '/help-center', component: <HelpCenter />},
  { path: '/collections', component: <Collections />},
  { path: '/authors-01', component: <Authors01 />},
  { path: '/authors-02', component: <Authors02 />},
  { path: '/Artists/Yann_Faisant', component: <YannFaisantProfile />},
  { path: '/wallet-connect', component: <WalletConnect />},
  { path: '/create-item', component: <CreateItem />},
  { path: '/create-collection', component: <CreateCollection />},
  { path: '/create-public-collection', component: <CreatePublicCollection />},
  { path: '/edit-profile', component: <EditProfile />},
  { path: '/ranking', component: <Ranking />},
  { path: '/login', component: <Login />},
  { path: '/sign-up', component: <SignUp />},
  { path: '/no-result', component: <NoResult />},
  { path: '/faq', component: <FAQ />},
  { path: '/contact-01', component: <Contact01 />},
  { path: '/contact-02', component: <Contact02 />},
  { path: '/creator-choice', component: <CreateChoices />},
  { path: '/settings', component: <SettingsPage />},
  { path: '/tokenize', component: <Tokenize />},
  { path: '/à_jamais_les_premiers', component: <Premiers />},
  { path: '/tokenized', component: <Tokenized />},
  { path: '/myCollections', component: <MyCollections />},
  { path: '/pending-tokenizations', component: <MyPendingArtworks />},
  { path: '/displayProfile', component:<DisplayProfile/>},
  { path: '/collection', component:<Collection/>},
  { path: '/learn', component:<Learn/>},
  { path: '/learn/*', component: <LearnResourceDetail/>},
  { path: '/order/tracking', component: <OrderTracking/>},
  { path: '/page/offer/received', component: <OfferList/>},
  { path: '/page/offer/sent', component: <OfferSent/>},

  { path: '/order/tracking', component: <OrderTracking/>},
  { path: '/page/confirmation', component: <ConfirmationPage/>}


]

export default routes;