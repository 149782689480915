export const usersActionTypes = {
    SET_USERS: 'SET_USERS',
    SET_CURRENT_USER_ID: 'SET_CURRENT_USER_ID',
    SET_SELECTED_USER: 'SET_SELECTED_USER',
    SET_CURRENT_USER: 'SET_CURRENT_USER',
    SET_LAZY_LISTED: 'SET_LAZY_LISTED',
    SET_SELECTED_LAZY_LISTED: 'SET_SELECTED_LAZY_LISTED',
    SET_LAZY_OWNED: 'SET_LAZY_OWNED',
    SET_ARTISTS: 'SET_ARTISTS',
    SET_ALL_USERS: 'SET_ALL_USERS',
    SET_MEMBERS: 'SET_MEMBERS',
    SET_COLLECTIONS: 'SET_COLLECTIONS',
    SET_SEARCHING_ARRAY: 'SET_SEARCHING_ARRAY'
}