import img1 from '../images/avatar/avt-1.jpg'
import img2 from '../images/avatar/avt-2.jpg'
import img3 from '../images/avatar/avt-3.jpg'
import img4 from '../images/avatar/avt-4.jpg'
import img5 from '../images/avatar/avt-5.jpg'
import img6 from '../images/avatar/avt-6.jpg'
import img7 from '../images/avatar/avt-7.jpg'
import img8 from '../images/avatar/avt-8.jpg'
import img9 from '../images/avatar/avt-9.jpg'

import yann from '../images/avatar/yann.jpg'


const topSellerData = [
    {
        img: yann,
        name: "Yann Faisant" ,
        price: "214.2 ETH",
        classPadding: ""
    },
    {
        img: yann,
        name: "Yann Faisant" ,
        price: "214.2 ETH",
        classPadding: ""
    },
    {
        img: yann,
        name: "Yann Faisant" ,
        price: "214.2 ETH",
        classPadding: ""
    },
    {
        img: yann,
        name: "Yann Faisant" ,
        price: "214.2 ETH",
        classPadding: ""
    },
    {
        img: yann,
        name: "Yann Faisant" ,
        price: "214.2 ETH",
        classPadding: ""
    },
    {
        img: yann,
        name: "Yann Faisant" ,
        price: "214.2 ETH",
        classPadding: ""
    },
    {
        img: yann,
        name: "Yann Faisant" ,
        price: "214.2 ETH",
        classPadding: ""
    },
    {
        img: yann,
        name: "Yann Faisant" ,
        price: "214.2 ETH",
        classPadding: ""
    },
    {
        img: yann,
        name: "Yann Faisant" ,
        price: "214.2 ETH",
        classPadding: ""
    },
    {
        img: yann,
        name: "Yann Faisant" ,
        price: "214.2 ETH",
        classPadding: ""
    },
    {
        img: yann,
        name: "Yann Faisant" ,
        price: "214.2 ETH",
        classPadding: ""
    },
    {
        img: yann,
        name: "Yann Faisant" ,
        price: "214.2 ETH",
        classPadding: ""
    },


]

export default topSellerData;