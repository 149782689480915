const LearnHeader = () => {
    return (
        <div>
            <div className="learn-header-section">
                <div className="learn-header-text-container">
                    <h1 className="learn-header-heading">Your NFT journey starts here.</h1>
                    <p className="learn-header-subheading">Guides, practical tips, and support articles for first-time creators, experienced collectors, and everyone in between.</p>
                </div>
            </div>
        </div>
    );
}

export default LearnHeader;
