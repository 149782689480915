import React from 'react';
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

const SeperatingHeader1 = () => {

    return (
        <div>
            
            
            <section className="tf-no-result tf-section">
                <div className="themesflat-container">
                    <div className="row">
                        <div className="col-12">
                        <h1 className="tf-title-heading ct style-2 fs-30 mg-bt-10 SeparatorTitle" style={{marginTop:'0px'}}>
                        Your journey with physical NFTs starts here.
                        </h1>
                        <h4 className="sub-title help-center mg-bt-32 ">
                        Join the world's finest painters, sculptors, photographers and collectors!
                        </h4>
                        </div>
                    </div>                                    
                </div>
            </section>
            
            
            
        </div>
    );
}

export default SeperatingHeader1;