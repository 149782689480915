import img1 from '../images/box-item/card-item-3.jpg'
import imga1 from '../images/avatar/yann.jpg'
import img2 from '../images/box-item/card-item-4.jpg'
import imga2 from '../images/avatar/avt-2.jpg'
import img3 from '../images/box-item/card-item-2.jpg'
import imga3 from '../images/avatar/avt-4.jpg'
import img4 from '../images/box-item/card-item-7.jpg'
import imga4 from '../images/avatar/avt-3.jpg'
import img5 from '../images/box-item/card-item8.jpg'
import imga5 from '../images/avatar/avt-12.jpg'
import img6 from '../images/box-item/card-item-9.jpg'
import imga6 from '../images/avatar/avt-1.jpg'
import img7 from '../images/box-item/image-box-6.jpg'
import imga7 from '../images/avatar/avt-4.jpg'
import img8 from '../images/box-item/image-box-11.jpg'
import imga8 from '../images/avatar/avt-3.jpg'
import imgCollection1 from '../images/avatar/avt-18.jpg'
import { useContract, useListings } from "@thirdweb-dev/react";
import marketplace from '../../marketplace'
import generalColletion from '../../generalCollection'

import axios from 'axios';
import web3 from '../../web3'


let todayPickData = [
    
];








export default todayPickData;